import React from 'react';
import PropTypes from 'prop-types';
import { AccessControlUtil, ApiUtil, FieldGroup, FloatingLabelFormField, JsonApiDecorator, RadioGroupControl, ValidationUtil, Core } from 'reactifi';
import i18n from 'lib/i18n';
import debounce from 'lodash.debounce';
import { LOGIN_OPTIONS } from '../../Registration/components/RegistrationBase';

export default class OrgWizardUserLogin extends React.Component {
  static propTypes = {
    canceling: PropTypes.bool,
    cancelComplete: PropTypes.func,
    clearMessages: PropTypes.func,
    currentOrganizationId: PropTypes.string,
    displayErrorMessage: PropTypes.func,
    goingDirection: PropTypes.string,
    goingToStep: PropTypes.number,
    login: PropTypes.string,
    loginType: PropTypes.string,
    onSelectUser: PropTypes.func,
    orgCapabilities: PropTypes.arrayOf(PropTypes.string),
    stepChangeCancel: PropTypes.func,
    stepChangeComplete: PropTypes.func,
    updateLogin: PropTypes.func
  };

  state = {
    alternateLoginEnabled: false,
    login: "",
    loginExists: false,
    validatingLoginExists: false
  };

  componentDidMount() {
    const alternateLoginEnabled = AccessControlUtil.hasCapability("Capabilities::AlternativeLogin", this.props.orgCapabilities);
    this.setState({
      alternateLoginEnabled,
      login: this.props.login || "",
      loginType: this.props.loginType || (alternateLoginEnabled ? "" : "email")
    });
    this.checkIfUserExists(this.props.login);
  }

  async componentDidUpdate(prevProps) {
    if (this.props.goingToStep !== null && this.props.goingToStep !== undefined && prevProps.goingToStep !== this.props.goingToStep) {
      if (this.props.goingDirection === 'forward') {
        this.gotoNextStep();
      } else {
        this.props.stepChangeComplete();
      }
    }
    if (this.props.canceling && !prevProps.canceling) {
      this.props.cancelComplete();
    }
  }

  checkIfUserExists = async (login = "") => {
    const { loginType } = this.state;
    let loginValid = login.length > 0;
    if (loginType === "email" && !(new RegExp(ValidationUtil.GetValidationPattern("email"))).test(login)) {
      loginValid = false;
    }
    this.setState({ loginExists: false, login, loginValid, currentUser: undefined });
    if (loginValid) {
      this.fetchExistingUser(login);
    }
  }

  fetchExistingUser = debounce(async login => {
    const { currentOrganizationId } = this.props;
    const { loginType } = this.state;
    this.setState({ validatingLoginExists: true });
    const result = await ApiUtil.loadApiData(`/api/data/users?filter[organization_id]=${currentOrganizationId}&filter[${loginType}]=${login}`);
    this.setState({ validatingLoginExists: false, loginExists: result.length > 0 });
    if (result.length > 0) {
      const currentUser = new JsonApiDecorator({}).newObject("users");
      Object.assign(currentUser, result[0]);
      currentUser.ruleSetIds = Object.keys(currentUser.rule_set_roles);
      this.setState({ currentUser });
    }
  }, 350);

  onChangeLoginType = value => {
    this.setState({ loginType: value, login: "", loginExists: false });
  }

  gotoNextStep = () => {
    const { clearMessages, displayErrorMessage, onSelectUser, stepChangeCancel, stepChangeComplete, updateLogin } = this.props;
    const { currentUser, login, loginType, loginExists, loginValid, validatingLoginExists } = this.state;
    if (!login || !loginValid) {
      if (loginType === "email") {
        displayErrorMessage(i18n.t('Valid email is required'));
      } else {
        displayErrorMessage(i18n.t('Username is required'));
      }
      stepChangeCancel();
    } else {
      clearMessages();
      if (validatingLoginExists) {
        stepChangeCancel();
      } else if (!loginExists) {
        updateLogin(login, loginType);
        stepChangeComplete();
      } else {
        stepChangeCancel();
        onSelectUser(currentUser);
      }
    }
  }

  render() {
    const { alternateLoginEnabled, login, loginExists, loginType } = this.state;
    let loginTypeText = '';
    switch (loginType) {
      case 'email':
        loginTypeText = i18n.t('email');
        break;
      case 'username':
        loginTypeText = i18n.t('username');
        break;
    }
    return (
      <>
        {alternateLoginEnabled && <FieldGroup>
          <Core.Layout mb="md">
            <RadioGroupControl
              name="has_email_checked"
              controlLabelText={i18n.t('Select Login Option')}
              valueList={LOGIN_OPTIONS}
              valueKey="value"
              labelKey="label"
              dataValue={loginType}
              valueOverride={true}
              onChange={this.onChangeLoginType}
            />
          </Core.Layout>
        </FieldGroup>}
        {loginType &&
          <FieldGroup>
            <FloatingLabelFormField
              type={loginType === "email" ? "email" : "text"}
              name={loginType}
              caption={loginType === "email" ? i18n.t('Email Address') : i18n.t('Username')}
              hintText={loginExists ? i18n.t('This user {{ loginType }} already exists. If you continue you will be editing this user\'s profile.', { loginType: loginTypeText }): undefined}
              required={true}
              dataValue={login}
              valueOverride={true}
              onChange={this.checkIfUserExists}
            />
          </FieldGroup>}
      </>
    );
  }
}
